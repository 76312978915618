import useSWR, { SWRResponse } from 'swr'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useAuth } from '@/contexts/AuthContext'

/**
 * Fanme Backend Request Config
 * @param method 'get' | 'post' | 'put' | 'delete' | 'patch'
 * @param url url
 * @param data data for post, put, patch
 * @param options FetcherOptions
 */
type FanmeBackendRequestConfig = {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
  url?: string
  data?: any
  options?: FetcherOptions
}

/**
 * Fetcher Options
 * @param auth true if need auth
 * @param token auth token
 * @param contentType axios content type
 * @param responseType axios response type
 */
type FetcherOptions = {
  auth?: boolean
  token?: string
  contentType?: string
  responseType?: any
}

const http = axios.create({
  baseURL: '/api/v1',
  withCredentials: true,
})

/**
 * fetcher with axios
 * @param args [url: string, conf: FanmeBackendRequestConfig]
 * @returns response data
 */
const fetcher = async (args: [url: string, conf: FanmeBackendRequestConfig]) => {
  const [url, conf] = args
  let r: AxiosResponse | null = null
  let config = {} as AxiosRequestConfig

  // 認証用ヘッダーの設定
  if (conf.options?.auth && conf.options?.token) {
    config = { headers: { Authorization: 'Bearer ' + conf.options.token } }
  }
  // Content-Typeの設定
  if (conf.options?.contentType) {
    if (config.headers)
      config.headers = { ...config.headers, 'Content-Type': conf.options.contentType }
    else config = { headers: { 'Content-Type': conf.options.contentType } }
  }
  // ResponseTypeの設定
  if (conf.options?.responseType) {
    config = { ...config, responseType: conf.options.responseType }
  }

  switch (conf.method) {
    case 'get':
      r = await http.get(url, config)
      break
    case 'delete':
      r = await http.delete(url, config)
      break
    case 'post':
      r = await http.post(url, conf.data, config)
      break
    case 'put':
      r = await http.put(url, conf.data, config)
      break
    case 'patch':
      r = await http.patch(url, conf.data, config)
      break
    default:
      throw new Error('invalid method')
  }

  if ((r?.status || 0) >= 400) throw new Error(r?.data?.message)
  return r?.data
}

/**
 * useSWR Wrapper
 * @param conf FanmeBackendRequestConfig
 * @param options useSWR options
 * @returns
 */
const useFanmeBackendRequest = <T>(
  conf: FanmeBackendRequestConfig | null,
  options?: any,
): SWRResponse<T> => {
  const { token } = useAuth()
  let url = null
  // URLが渡されている かつ （認証が必要ない または 認証が必要でトークンがある） 場合はURLを渡す
  if (conf?.url && (!conf?.options?.auth || token)) url = [conf.url, conf]
  if (conf) conf.options = { ...conf.options, token: token ? token : '' }
  return useSWR<T>(url, fetcher, options)
}

const useFanmeBackendGet = <T>(
  url: string | null,
  fetcherOptions: FetcherOptions = { auth: true },
  options?: any,
): SWRResponse<T> =>
  useFanmeBackendRequest<T>(
    url
      ? {
          method: 'get',
          url: url,
          options: fetcherOptions,
        }
      : null,
    options,
  )

const useFanmeBackendPost = <T>(
  url: string | null,
  data: any,
  fetcherOptions: FetcherOptions = { auth: true },
  options?: any,
): SWRResponse<T> =>
  useFanmeBackendRequest<T>(
    url
      ? {
          method: 'post',
          url: url,
          data: data,
          options: fetcherOptions,
        }
      : null,
    options,
  )

const useFanmeBackendPut = (
  url: string | null,
  data: any,
  fetcherOptions: FetcherOptions = { auth: true },
  options?: any,
) =>
  useFanmeBackendRequest(
    url
      ? {
          method: 'put',
          url: url,
          data: data,
          options: fetcherOptions,
        }
      : null,
    options,
  )

const useFanmeBackendDelete = (
  url: string | null,
  data: any,
  fetcherOptions: FetcherOptions = { auth: true },
  options?: any,
) =>
  useFanmeBackendRequest(
    url
      ? {
          method: 'delete',
          url: url,
          data: data,
          options: fetcherOptions,
        }
      : null,
    options,
  )

const useFanmeBackendPatch = (
  url: string | null,
  data: any,
  fetcherOptions: FetcherOptions = { auth: true },
  options?: any,
) =>
  useFanmeBackendRequest(
    url
      ? {
          method: 'patch',
          url: url,
          data: data,
          options: fetcherOptions,
        }
      : null,
    options,
  )

const postBackend = (url: string, data: any, config?: any) => {
  return http.post(url, data, config)
}

/**
 * Get login user data
 * @param options useSWR options
 * @returns
 */
const useUser = (options?: any) => {
  return useFanmeBackendGet<any>('/fanme/api/creators/self', options)
}

const useCreator = (userId?: string | string[], options?: any) => {
  return useFanmeBackendGet<any>(`/fanme/api/creators/${userId}`, options)
}

export type FetchFanmeDataOptions = {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
}
const fetchFanmeData = async (
  url: string,
  option: FetchFanmeDataOptions = {
    method: 'get',
  },
) => await fetcher([url, option])

export {
  useFanmeBackendGet,
  useFanmeBackendPost,
  useFanmeBackendPut,
  useFanmeBackendPatch,
  useFanmeBackendDelete,
  postBackend,
  useUser,
  useCreator,
  fetchFanmeData,
}

export type { FetcherOptions }
