import styled from '@emotion/styled'
import React, { PropsWithChildren } from 'react'
import { Colors } from '@/fanmeUi/constants/style'

const HeaderWrap = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`
const Header = styled.header<{
  textColor?: string
  bgColor?: string
  headerHeight?: string
  headerLineHeight?: string
}>`
  display: block;
  background-color: ${props => props.bgColor || Colors.TRANSPARENT};
  color: ${props => props.textColor || Colors.BLACK};
  font-weight: 700;
  height: ${props => props.headerHeight || '56px'};
  text-align: center;
  line-height: ${props => props.headerLineHeight || '56px'};
`

const HeaderSideButton = styled.div<{ width: number }>`
  width: ${p => p.width}px;
  height: 100%;
  align-content: center;
  > * {
    display: inline-block;
    vertical-align: middle;
  }
`

export const PrimaryHeader = styled.header<{
  textColor?: string
  bgColor?: string
  headerHeight?: string
  headerLineHeight?: string
}>`
  display: block;
  background-color: ${props => props.bgColor || Colors.BLACK};
  color: ${props => props.textColor || Colors.WHITE};
  font-weight: 700;
  height: ${props => props.headerHeight || '56px'};
  text-align: center;
  line-height: ${props => props.headerLineHeight || '56px'};
`

type FmeHeaderProps = {
  left?: any
  right?: any
  sideButtonWidth?: number
} & PropsWithChildren
const FmeHeader = ({ children, left, right, sideButtonWidth = 88 }: FmeHeaderProps) => {
  return (
    <Header>
      <HeaderWrap>
        <HeaderSideButton width={sideButtonWidth}>{left}</HeaderSideButton>
        <div style={{ flex: 1 }}>{children}</div>
        <HeaderSideButton width={sideButtonWidth}>{right}</HeaderSideButton>
      </HeaderWrap>
    </Header>
  )
}

export { FmeHeader }
