import React from 'react'
import styled from '@emotion/styled'
import { Colors, Styles } from '@/fanmeUi/constants/style'
import { ContextType, contextTypeColor } from '@/fanmeUi/constant'

const Panel = styled.div<FmePanelProps>`
  height: ${props => `${props.panelHeight}` || ''};
  background-color: ${Colors.WHITE};
  overflow: hidden;
  border-radius: ${props =>
    [
      props.leftTopRadius === undefined ? Styles.radius : props.leftTopRadius,
      props.rightTopRadius === undefined ? Styles.radius : props.rightTopRadius,
      props.rightBottomRadius === undefined ? Styles.radius : props.rightBottomRadius,
      props.leftBottomRadius === undefined ? Styles.radius : props.leftBottomRadius,
    ]
      .map(v => (Number.isInteger(v) ? `${v}px` : v))
      .join(' ')};
`

export const FmeDivider = styled.hr`
  display: block;
  height: 1px;
  border: none;
  background-color: ${Colors.VERY_LIGHT_GRAY};
  margin: ${Styles.space} 0;
`

const PanelInner = styled.div`
  padding: 16px;
`
export const TextSpan = styled.span<{ type: ContextType }>`
  color: ${({ type }) => contextTypeColor[type].dark};
`

type FmePanelProps = {
  leftTopRadius?: number
  rightTopRadius?: number
  leftBottomRadius?: number
  rightBottomRadius?: number
  panelHeight?: string
} & WithChildren
const FmePanel = (props: FmePanelProps) => {
  return <Panel {...props} />
}

const FmePanelInner = ({ children }: WithChildren) => {
  return <PanelInner>{children}</PanelInner>
}

const FmeSpace = ({ size = 1 }: { size?: number }) => <div style={{ height: 12 * size }} />
export { FmePanel, FmeSpace, FmePanelInner }

type FmeTextProps = {
  color?: string
  size?: number
  fontWeight?: number
}

export const FmeH1 = styled.h1``
export const FmeH2 = styled.h2<FmeTextProps>`
  font-size: 18px;
  font-weight: ${props => props.fontWeight || 'bold'};
  color: ${Colors.BLACK};
  margin: 0;
`
export const FmeH3 = styled.h3<FmeTextProps>`
  font-size: 14px;
  font-weight: ${props => props.fontWeight || 'bold'};
  color: ${Colors.BLACK};
  margin: 0;
`

export const FmeH5 = styled.h5<{ color?: string }>`
  font-size: 14px;
  font-weight: 700;
  color: ${props => (props.color ? props.color : Colors.BLACK)};
  margin-bottom: 4px;
  > a {
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
  }
`

export const FmeP = styled.p<{ textColor: string; width?: string; height?: string }>`
  color: ${({ textColor }) => textColor || Colors.BLACK};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
`

type FmeThumbProps = {
  src: string
  size?: number
  radius?: number
}

const ImageContainer = styled.div<{
  src: string
  width?: number
  radius?: number
  height?: number
}>`
  display: block;
  background: url('${({ src }) => src}');
  background-size: cover;
  width: ${({ width }) => width || 64}px;
  min-width: ${({ width }) => width || 64}px;
  height: ${({ height }) => height || 64}px;
  border-radius: ${({ radius }) => radius || 16}px;
  overflow: hidden;
  background-color: ${Colors.LIGHT_GRAY_BLUE};
`
export const FmeThumb = ({ src, size = 64, radius }: FmeThumbProps) => {
  return <ImageContainer src={src} width={size} height={size} radius={radius} />
}
