import React from 'react'
import clsx from 'clsx'

type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'header-title'
  | 'header-item'
  | 'page-title'
  | 'feature-description'
  | 'feature-title-in-box'
  | 'feature-description-in-box'
  | 'feature-sub-title-in-box'
  | 'contact-description'
  | 'top-about-description'

interface TypographyProps {
  variant: TypographyVariant
  children: React.ReactNode
  className?: string
}

const variantClasses: Record<TypographyVariant, string> = {
  h1: 'text-3xl font-bold text-fanme-black',
  h2: 'text-2xl font-medium text-fanme-black',
  h3: 'text-xl font-medium text-fanme-black',
  body1: 'text-base font-normal text-fanme-gray',
  body2: 'text-sm font-normal text-fanme-gray',
  caption: 'text-xs font-light text-fanme-gray',
  'header-title': 'text-[22px] font-[700] text-BLACK font-roboto',
  'header-item':
    'font-["Noto_Sans_JP"] font-normal text-[13px] leading-[19px] text-edge-cap tracking-[0.03em] text-BLACK',
  'page-title':
    'font-roboto font-black text-[32px] sm:text-[41px] leading-[60px] tracking-[0.03em] text-WHITE',
  'feature-description':
    'text-BLACK font-sansJP tracking-[0.03em] leading-[170%] sm:leading-[140%] font-bold text-[16px] sm:text-[14px]',
  'feature-title-in-box':
    'text-WHITE text-center font-["Noto_Sans_JP"] font-black text-[20px] sm:text-[20px] md:text-[22px] lg:text-[26px] leading-[18px] tracking-[0.1em]',
  'feature-description-in-box':
    'text-WHITE text-center font-["Noto_Sans_JP"] font-bold text-[16px] sm:text-[16px] md:text-[16px] lg:text-[22px]',
  'feature-sub-title-in-box':
    'text-WHITE text-center font-["Roboto"] font-semibold leading-[180%] tracking-[0.2em] whitespace-nowrap text-[14px] sm:text-[15px] md:text-[16px] lg:text-[24px] sm:leading-[1]',
  'contact-description':
    'text-BLACK font-["Noto_Sans_JP"] font-bold text-[13px] sm:text-[14px] md:text-[14px] lg:text-[16px] leading-[150%]',
  'top-about-description':
    'text-WHITE tracking-[0.03em] leading-[20px] sm:leading-[24px] font-bold z-[3] text-[14px] sm:text-[18px] md:text-[16px] lg:text-[17px]',
}

const Typography: React.FC<TypographyProps> = ({ variant, children, className = '' }) => {
  const baseClasses = 'font-sans m-0'
  const variantClass = variantClasses[variant]

  return <p className={clsx(baseClasses, variantClass, className)}>{children}</p>
}

export default Typography
