import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react'
import { THEMES } from '@/types/Creator.type'
import {
  BACKGROUND_COLOR_DARK,
  BACKGROUND_COLOR_LIGHT,
  Colors,
  themeColors,
} from '@/constants/styles/color'
import { useCurrentUser } from '@/contexts/AuthContext'

type ThemeType = 'Dark' | 'Light'

export type ThemeColors = {
  text: string
  lighter: any
  normal: any
}

interface IThemeContext {
  isDark: boolean
  theme: THEMES
  themeType: ThemeType
  color: string
  themeColors: ThemeColors
}
const INITIAL_STATE = {
  theme: THEMES.light15,
  themeType: 'Light' as ThemeType,
  color: '',
}
const INITIALCONTEXT = {
  themeColors: themeColors[INITIAL_STATE.themeType.toLowerCase()],
  theme: THEMES.light15,
  themeType: 'Light' as ThemeType,
  color: '',
  isDark: false,
}
interface IAction {
  type: string
  payload: { theme: THEMES; themeType: ThemeType; color: string }
}

const ThemeContext = createContext<IThemeContext>(INITIALCONTEXT)

export const getThemeHex = (theme: THEMES, themeType: ThemeType) => {
  let themeColor = Colors.WHITE

  if (themeType === 'Dark') {
    themeColor = Colors.PRIMARY_GRAY
    BACKGROUND_COLOR_DARK.map(item => {
      if (item.colorName === theme) {
        themeColor = item.colorValue
      }
    })
  } else {
    BACKGROUND_COLOR_LIGHT.map(item => {
      if (item.colorName === theme) {
        themeColor = item.colorValue
      }
    })
  }

  return themeColor
}

export const extractThemeContext = (theme: THEMES) => {
  const retTheme = roundTheme(theme)
  const themeType: ThemeType = theme.includes('dark') ? 'Dark' : 'Light'
  const themeColor = getThemeHex(theme, themeType)
  return {
    theme: retTheme,
    themeType: themeType,
    color: themeColor,
  }
}

export const getCurrentTheme = () => {
  // TODO: localStorageから取得？バックエンドからじゃなくて？
  return (
    typeof localStorage !== 'undefined' ? roundTheme(localStorage.getItem('themeName')) : 'light15'
  ) as THEMES
}
const roundTheme = (t?: string | null): THEMES => {
  if (!t) {
    return THEMES.light15
  }
  t = t.toLowerCase()
  if (t === ('light' as THEMES)) return THEMES.light15
  else if (t === ('dark' as THEMES)) return THEMES.dark15
  else if (Object.keys(THEMES).includes(t)) return t as THEMES
  return THEMES.light15
}

const ThemeProvider: FC<{
  children: any
  initTheme?: THEMES
  fixedTheme?: THEMES
  onThemeChanged?: (t: THEMES) => void
}> = ({ children, initTheme, fixedTheme, onThemeChanged }) => {
  const { currentUser } = useCurrentUser()
  const ctx = extractThemeContext(fixedTheme || initTheme || THEMES.light15)

  const [theme, setTheme] = useState<THEMES>(ctx.theme)
  const [themeType, setThemeType] = useState(ctx.themeType || INITIAL_STATE.themeType)
  const [color, setColor] = useState(ctx.color)

  useEffect(() => {
    // localstorageはブラウザのみサポートしているので、クライアントレンダリングにする
    if (!fixedTheme) {
      const localTheme = getCurrentTheme()
      setTheme(localTheme)
    }
  }, [])

  useEffect(() => {
    const ctx = extractThemeContext(theme)
    setThemeType(ctx.themeType)
    setColor(ctx.color)

    onThemeChanged && onThemeChanged(theme)
  }, [theme])

  useEffect(() => {
    setCtxTheme(currentUser?.profile?.theme_color || THEMES.light15)
  }, [currentUser])

  const setCtxTheme = useCallback(
    (t?: string | null | THEMES) => {
      if (fixedTheme) {
        return
      }

      const toTheme = roundTheme(t)
      if (toTheme == theme) {
        return
      }
      localStorage.setItem('themeName', toTheme)
      setTheme(toTheme)
    },
    [theme, fixedTheme],
  )

  const value = {
    theme,
    themeType,
    color,
    themeColors: themeColors[themeType.toLowerCase()],
    isDark: themeType.toLowerCase() === 'dark',
  }

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export const getThemeTextColor = (theme: THEMES) => {
  if (theme.includes('dark')) return Colors.WHITE
  return Colors.BLACK
}
export const getThemeBgColor = (theme: THEMES) => {
  return getThemeHex(theme, theme.startsWith('dark') ? 'Dark' : 'Light')
}

export default ThemeProvider

export const useThemeContext = () => useContext(ThemeContext)
