import { getApp, getApps, initializeApp } from 'firebase/app'
import localforage from 'localforage'
import {
  getMessaging as getFirebaseMessaging,
  getToken,
  isSupported,
  onMessage,
} from '@firebase/messaging'
import { firebaseConfig } from '../config'
import type { MessagePayload, NextFn } from '@firebase/messaging'

const fcmTokenKey = 'fanme_fcm_token'

const getMessaging = () => {
  if (getApps().length === 0) {
    return null
  }
  return getFirebaseMessaging(getApp())
}

export const listenOnMessage = (callback: NextFn<MessagePayload>) => {
  const messaging = getMessaging()
  if (!messaging) {
    return
  }
  onMessage(messaging, callback)
}

export const initializeFirebaseMessaging = async (): Promise<boolean> => {
  if (getApps().length > 0) {
    // firebase app already initialized, and Notification is not granted, maybe.
    return false
  }
  if (!(await isSupported())) {
    // firebase not supported
    return false
  }

  initializeApp(firebaseConfig.appOptions)

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(firebaseConfig.messaging.sw)
      .then(() => {
        console.log('[sw] messaging worker is installed')
      })
      .catch(err => {
        console.error('[sw] messaging worker installation is failed', err)
      })
  }

  const found = await localforage.getItem<string>(fcmTokenKey)
  if (found) {
    // token is already published
    return true
  }

  try {
    const messaging = getMessaging()
    if (!messaging) {
      return false
    }
    const permission = await Notification.requestPermission()
    if (!permission || permission !== 'granted') {
      // canceled or denied
      return false
    }

    const token = await getToken(messaging, {
      vapidKey: firebaseConfig.messaging.vapidKey,
    })
    if (!token) {
      // reject by vapid key error or something
      return false
    }
    await localforage.setItem<string>(fcmTokenKey, token)
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}
