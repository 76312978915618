import React, { useEffect } from 'react'
import { LPNavigationProvider } from '@/contexts/LPNavigationContext'
import HeaderMenu from '@/components/lp/HeaderMenu'
import Footer from '@/components/lp/section/footer'

type LPLayoutProps = {
  children: React.ReactNode
}

const LPLayout: React.FC<LPLayoutProps> = ({ children }) => {
  useEffect(() => {
    // 本番のみFacebook Pixelを読み込む
    if (process.env.NODE_ENV === 'production') {
      import('react-facebook-pixel')
        .then(x => x.default)
        .then(ReactPixel => {
          // Pixelタグ挿入
          ReactPixel.init(process.env.FB_PIXEL_ID || '')
          ReactPixel.pageView()
        })
    }
  }, [])

  return (
    <LPNavigationProvider>
      <HeaderMenu />
      <main>{children}</main>
      <Footer />
    </LPNavigationProvider>
  )
}

export default LPLayout
