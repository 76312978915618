import styled from '@emotion/styled'
import React, { HTMLAttributes } from 'react'

const Overlay = styled.div<WithVisibility>`
  display: ${(props: any) => (props.visible ? 'block' : 'none')};
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
`

type FmeOverlayLayer = WithVisibility & HTMLAttributes<HTMLDivElement>
const FmeOverlayLayer = ({ visible = true, ...props }: FmeOverlayLayer) => {
  return <Overlay {...props} visible={visible} />
}

export { FmeOverlayLayer }
