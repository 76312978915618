import React, { ButtonHTMLAttributes, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/fanmeUi/constants/style'
import { useIsMounted } from '@/utils/hooks/is_mounted'

type FmeButtonStyle = 'primary' | 'black' | 'black-outline'
type FmeButtonSize = 'small' | 'normal'
type ButtonProps = {
  fullWidth?: boolean
  disabled?: boolean
  buttonSize?: FmeButtonSize
  buttonStyle?: FmeButtonStyle

  onClick?: () => Promise<void>
}
const Button = styled.button<
  ButtonHTMLAttributes<any> & {
    buttonSize?: FmeButtonSize
    fullWidth?: boolean
    buttonStyle: FmeButtonStyle
  }
>`
  display: inline-block;
  background-color: ${({ buttonStyle, disabled, primary }: any) => {
    if (disabled) {
      return Colors.VERY_LIGHT_GRAY
    }
    switch (buttonStyle) {
      case 'primary':
        return Colors.FANME_YELLOW
      case 'black-outline':
        return Colors.WHITE
    }

    return Colors.BLACK
  }};
  border-radius: 24px;
  font-size: ${({ buttonSize }) => {
    const s = buttonSize === 'small' ? 12 : 14
    return `${s}px`
  }};
  border: ${({ buttonStyle, disabled, primary }: any) => {
    if (disabled) {
      return Colors.VERY_LIGHT_GRAY
    }
    switch (buttonStyle) {
      case 'black-outline':
        return `2px solid ${Colors.BLACK}`
    }

    return 'none'
  }};
  height: ${({ buttonSize }) => {
    const s = buttonSize === 'small' ? 32 : 48
    return `${s}px`
  }};
  padding: ${({ buttonSize }) => {
    const s = buttonSize === 'small' ? 12 : 24
    return `0 ${s}px`
  }};
  color: ${({ disabled, buttonStyle }) => {
    return disabled || buttonStyle === 'black' ? Colors.WHITE : Colors.BLACK
  }};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-weight: 700;
  width: ${props => (!props.fullWidth ? '100%' : 'auto')};
  &:hover {
  }
`
type FmeButtonProps = WithChildren & ButtonProps
const FmeButton = ({
  children,
  onClick,
  buttonStyle = 'primary',
  buttonSize = 'normal',
  disabled = false,
}: FmeButtonProps) => {
  const [clicked, setClicked] = useState(false)
  const isMounted = useIsMounted()

  useEffect(() => {
    if (clicked) {
      if (onClick) {
        onClick().then(() => {
          if (isMounted()) {
            setClicked(false)
          }
        })
      } else {
        if (isMounted()) {
          setClicked(false)
        }
      }
    }
  }, [clicked])
  return (
    <Button
      buttonSize={buttonSize}
      buttonStyle={buttonStyle}
      disabled={clicked || disabled}
      onClick={() => {
        setClicked(true)
      }}
    >
      {children}
    </Button>
  )
}

export { FmeButton }
