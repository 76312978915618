import styled from '@emotion/styled'
import React, { HTMLAttributes } from 'react'
import { FmePanel } from '@/fanmeUi/shared/base'
import { PrimaryHeader } from '@/fanmeUi/shared/header'

const PopupLayerWrap = styled.div`
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // safariのvhは全画面になってしまうので、100%は下のツールバーを除外する
`

const PopupLayerWrapInner = styled.div<FmePopupProps>`
  width: 100%;
  height: ${props => `${props.innerHeight}` || ''};
  min-height: ${props => `${props.minHeight}` || ''};
  max-width: 480px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate3d(-50%, 0, 0);
`

type FmePopupHeaderProps = {
  title?: string
  headerHeight?: string
  headerLineHeight?: string
}
const FmePopupHeader = ({ title, headerHeight, headerLineHeight }: FmePopupHeaderProps) => {
  return (
    <PrimaryHeader headerLineHeight={headerLineHeight} headerHeight={headerHeight}>
      {title}
    </PrimaryHeader>
  )
}

type FmePopupProps = {
  header?: FmePopupHeaderProps
  layerOrder?: number
  panelHeight?: string
  innerHeight?: string
  minHeight?: string
} & WithChildren &
  FmePopupHeaderProps

const FmePopup = ({ header, children }: FmePopupProps) => {
  return (
    <>
      {header && <FmePopupHeader {...header} />}
      {children}
    </>
  )
}

const AppPageHeaderSide = styled.div<{ width?: number }>`
  width: ${({ width }) => width}px;
  padding: 0 12px;
  z-index: 1;
`

const PrimaryHeaderWrap = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
`

type FmePopupLightHeaderProps = {
  left?: any
  right?: any
  bgColor?: string
  textColor?: string
  sideButtonWidth?: number
} & WithChildren &
  HTMLAttributes<HTMLDivElement>

export const FmePopupLightHeader = ({
  left,
  right,
  children,
  bgColor,
  textColor,
  sideButtonWidth,
  ...props
}: FmePopupLightHeaderProps) => {
  return (
    <PrimaryHeader {...props} bgColor={bgColor} textColor={textColor}>
      <PrimaryHeaderWrap>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            textAlign: 'center',
          }}
        >
          {children}
        </div>
        <AppPageHeaderSide width={sideButtonWidth}>{left}</AppPageHeaderSide>

        <div style={{ flex: 1 }} />
        <AppPageHeaderSide width={sideButtonWidth}>{right}</AppPageHeaderSide>
      </PrimaryHeaderWrap>
    </PrimaryHeader>
  )
}

const FmeBottomPopup = ({
  layerOrder,
  header,
  children,
  panelHeight,
  innerHeight,
  headerHeight,
  headerLineHeight,
  minHeight,
}: FmePopupProps) => {
  return (
    <PopupLayerWrap>
      <PopupLayerWrapInner innerHeight={innerHeight} minHeight={minHeight}>
        <FmePanel leftBottomRadius={0} rightBottomRadius={0} panelHeight={panelHeight}>
          {header && (
            <FmePopupHeader
              headerLineHeight={headerLineHeight}
              headerHeight={headerHeight}
              {...header}
            />
          )}
          {children}
        </FmePanel>
      </PopupLayerWrapInner>
    </PopupLayerWrap>
  )
}

export { FmePopup, FmeBottomPopup }
