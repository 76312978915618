import React, { createContext, FC, useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { fetchFanmeData, postBackend } from '@/libs/fanme_backend'

interface IGeneralEventContext {
  activeEvent: IGeneralEvent | null // 開催中イベント
  actionable: IGeneralEventActionable | null // アクション可否
  setActionable: (actionable: IGeneralEventActionable | null) => void
  getActiveEvent: (force: boolean) => Promise<IGeneralEvent | null> // 参加中のランキングイベントを取得
  getGeneralEvent: (accountIdentity: string, eventId: number) => Promise<IGeneralEvent | null> // 任意のランキングイベントを取得
  getUserRanking: (accountIdentity: string, eventId: number) => Promise<Array<IGeneralEventUser>> // ユーザーランキングを取得
  postYell: (accountIdentity: string, logType: YellLogType, count: number) => Promise<number> // エールを登録
  getActionable: (accountIdentity: string) => Promise<IGeneralEventActionable | null> // アクション可否を取得
  getEventStatus: (event: IGeneralEvent) => GeneralEventStatus // イベントのステータスを取得
  YellLogType: typeof YellLogType
  YellCount: typeof YellCount
}
enum YellLogType {
  Click = 0,
  Purchase = 1,
  Share = 2,
  Tip = 3,
}
enum YellCount {
  ClickMax = 200,
  Share = 400,
}
const GeneralEventContext = createContext<IGeneralEventContext>({
  activeEvent: null,
  actionable: null,
  setActionable: () => {},
  getActiveEvent: () => Promise.resolve(null),
  getGeneralEvent: () => Promise.resolve(null),
  getUserRanking: () => Promise.resolve([]),
  getActionable: () => Promise.resolve(null),
  postYell: () => Promise.resolve(0),
  getEventStatus: () => GeneralEventStatus.Preparing,
  YellLogType,
  YellCount,
})

export interface IGeneralEvent {
  id: number
  creator_id: number
  creator: {
    account_identity: string
    icon: string
    is_public: boolean
    name: string
    uid: string
  }
  event_type: number
  prepare_at: Date
  start_at: Date
  end_at: Date
  archived_at: Date
}

export interface IGeneralEventUser {
  general_event_id: number
  user_id: number
  rank: number
  yell_count: number
  aggregation_at: Date
  previous_rank: number
  previous_yell_count: number
  previous_aggregation_at: Date
  user: {
    account_identity: string
    icon: string
    is_public: boolean
    name: string
    uid: string
  }
}

export interface IGeneralEventActionable {
  can_yell_for_click: boolean
  can_yell_for_share: boolean
  today_yell_count: number
}

export enum GeneralEventStatus {
  Preparing = 'preparing',
  Ongoing = 'ongoing',
  AnnouncingResults = 'announcing_results',
  EventFinished = 'event_finished',
}

const GeneralEventProvider: FC<{ children: React.ReactElement }> = ({ children }) => {
  const [activeEvent, setActiveEvent] = useState<IGeneralEvent | null>(null)
  const [actionable, setActionable] = useState<IGeneralEventActionable | null>(null)
  const { query } = useRouter()

  // 開催中のランキングイベントを取得
  const getActiveEvent = async (force: boolean): Promise<IGeneralEvent | null> => {
    // ルートにクリエイターIDがない場合は無視
    if (!query.creator_id) return null
    // すでに取得済みでアカウントが変わっていない場合は取得済みのものを返す
    if (!force && activeEvent && query.creator_id === `@${activeEvent.creator.account_identity}`) {
      return activeEvent
    }
    const url = `/fanme/api/general/event/${query.creator_id}/active`
    const result = await fetchFanmeData(url, { method: 'get' })
    if (result.status >= 400) {
      return null
    }
    setActiveEvent(result)
    return result
  }

  // 任意のランキングイベントを取得
  const getGeneralEvent = async (
    accountIdentity: string,
    eventId: number,
  ): Promise<IGeneralEvent | null> => {
    if (!accountIdentity) return null
    const result = await fetchFanmeData(
      `/fanme/api/general/event/@${accountIdentity}/event/${eventId}`,
      {
        method: 'get',
      },
    )
    if (result.status >= 400) {
      return null
    }
    return result
  }
  // ユーザーランキングを取得
  const getUserRanking = async (
    accountIdentity: string,
    eventId: number,
  ): Promise<Array<IGeneralEventUser>> => {
    if (!accountIdentity) return []
    const result = await fetchFanmeData(
      `fanme/api/general/event/@${accountIdentity}/event/${eventId}/users`,
      { method: 'get' },
    )
    if (result.status >= 400) {
      return []
    }
    return result.general_event_users
  }

  // アクション可否を取得
  const getActionable = async (
    accountIdentity: string,
  ): Promise<IGeneralEventActionable | null> => {
    setActionable(null)
    if (!accountIdentity) return null
    const result = await fetchFanmeData(
      `/fanme/api/creators/self/general/event/@${accountIdentity}/actionable`,
      { method: 'get' },
    )
    if (result.status >= 400) {
      return null
    }
    setActionable(result)
    return result
  }

  // エールを登録
  const postYell = async (
    accountIdentity: string,
    logType: number,
    count: number,
  ): Promise<number> => {
    if (!accountIdentity) return 0
    if (![0, 2].includes(logType)) return 0
    if (count == 0) return 0
    const result = await postBackend(
      `/fanme/api/creators/self/general/event/@${accountIdentity}/yell`,
      { log_type: logType, count: count },
    )
    if (result.status >= 400) {
      return 0
    }
    return result.data.yell_count
  }

  const getEventStatus = (event: IGeneralEvent): GeneralEventStatus => {
    const now = new Date()
    if (now >= new Date(event.prepare_at) && now < new Date(event.start_at)) {
      return GeneralEventStatus.Preparing
    } else if (now >= new Date(event.start_at) && now < new Date(event.end_at)) {
      return GeneralEventStatus.Ongoing
    } else if (now >= new Date(event.end_at) && now < new Date(event.archived_at)) {
      return GeneralEventStatus.AnnouncingResults
    } else if (now >= new Date(event.archived_at)) {
      return GeneralEventStatus.EventFinished
    }
    return GeneralEventStatus.Preparing
  }

  const value = {
    activeEvent,
    actionable,
    setActionable,
    getActiveEvent,
    getGeneralEvent,
    getUserRanking,
    getActionable,
    postYell,
    getEventStatus,
    YellLogType,
    YellCount,
  }
  return <GeneralEventContext.Provider value={value}>{children}</GeneralEventContext.Provider>
}
export default GeneralEventProvider
export const useGeneralEvent = () => useContext(GeneralEventContext)
