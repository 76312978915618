import { FirebaseOptions } from 'firebase/app'

const isProduction = process.env.NODE_ENV === 'production'

const appOptions = (): FirebaseOptions => {
  if (isProduction) {
    return {
      apiKey: 'AIzaSyCOwsrWaT3JALvEkfjsadEN27LMmw0X2Gs',
      authDomain: 'fanme-339800.firebaseapp.com',
      projectId: 'fanme-339800',
      storageBucket: 'fanme-339800.appspot.com',
      messagingSenderId: '896475418310',
      appId: '1:896475418310:web:1749ba75d0b0c9bdd9d1c7',
    }
  }
  return {
    apiKey: 'AIzaSyDxKkCeOx6eJQF9IzF9Ifq3-rQhr3CsTG0',
    authDomain: 'fanme-stg.firebaseapp.com',
    projectId: 'fanme-stg',
    storageBucket: 'fanme-stg.appspot.com',
    messagingSenderId: '513362980422',
    appId: '1:513362980422:web:f81255ed33cd84d551a447',
  }
}

const messagingConfig = () => {
  if (isProduction) {
    return {
      sw: '/sw/firebase-messaging.js',
      vapidKey:
        'BG-5ojBW0QFsVJP37qADkYUW0oPpMVeDRw8gNI7E6fCzixmPv31h0b5fQfB2VnqY8yH-ZdKH9pT8ClFTpu_JeNk',
    }
  }
  return {
    sw: '/sw/firebase-messaging.stg.js',
    vapidKey:
      'BMJqfjlya-7hpfmCDW2BArfyFXC-cUNA4yq2zCs0AX8fCEUquTXs6lIq-AN3LNzuQojY9hx-Bwe8uSNa9ls1TbQ',
  }
}

export const firebaseConfig = {
  appOptions: appOptions(),
  messaging: messagingConfig(),
}
