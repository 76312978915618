import { useEffect } from 'react'
import { useCurrentUser } from '@/contexts/AuthContext'
import { ga4SetUserId } from '@/libs/ga_event'

export const useSetGaUserInformation = () => {
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (currentUser) {
      ga4SetUserId(currentUser.uid)
    } else {
      ga4SetUserId(null)
    }
  }, [currentUser])
}
