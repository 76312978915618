const Size = {
  space: 12,
}

export const Colors = {
  WHITE: '#fff',
  ULTRA_LIGHT_GRAY: '#f8f8f8',
  PRIMARY_GRAY: '#222222',
  BK_GRAY: '#EFF5FF',
  BK_GRAY_1: '#F5F5F5',
  VERY_LIGHT_GRAY: '#eee',
  LOGO_GRAY: '#D6E4F0',
  LIGHT_GRAY: '#ccc',
  GRAY: '#999',
  LESS_DARK_GRAY: '#666',
  DARK_GRAY: '#444',
  LIGHT_BLUE: '#20D5EC',
  BLACK: '#333',
  ULTRA_BLACK: '#010101',
  OFFICIAL: '#20d5ec',
  ORANGE: '#FFA800',
  FANME_YELLOW: '#ffe600',
  ERROR: '#ff5050',
  SUCCESS: '#02bd9e',
  RED: '#FF5050',
  TRANSPARENT_GRAY: 'rgba(0, 0, 0, 0.5)',
  TRANSPARENT_LIGHT_GRAY: 'rgba(0, 0, 0, 0.3)',
  TRANSPARENT: '#00000000',
  LIGHT_GRAY_BLUE: '#E4EBF7',
  GRAY_BLUE: '#C3CEDF',
  PURE_BLACK: '#000000',
}

export type ContextType = 'info' | 'danger' | 'warning' | 'normal'
export const contextTypeColor: {
  [key in ContextType]: {
    light: string
    lightest: string
    dark: string
    darkest: string
    color: string
  }
} = {
  info: {
    light: '#E4EBF7',
    lightest: '#F5F8FC',
    dark: '#C3CEDF',
    darkest: '#A3B1C8',
    color: '#6981A6',
  },
  danger: {
    light: '#FDE8E8',
    lightest: '#FEF5F5',
    dark: '#F7C4C4',
    darkest: '#F4A2A2',
    color: Colors.RED,
  },
  warning: {
    light: '#FFF3E0',
    lightest: '#FFF9F0',
    dark: '#FFD9A3',
    darkest: '#FFC97F',
    color: '#FFA800',
  },
  normal: {
    light: '#F5F5F5',
    lightest: '#F8F8F8',
    dark: '#D6D6D6',
    darkest: '#BFBFBF',
    color: '#999999',
  },
}

export const Styles = {
  radius: 16,
  boxShadowSmall: `0px 0 5px rgba(0, 0, 0, .05)`,
  boxShadowMiddle: `0px 0 16px rgba(0, 0, 0, .08)`,
}

export const SnsColors = {
  TIKTOK: '#010101',
  TWITTER: '#1da1f2',
  LINE: '#06c755',
  YOUTUBE: '#ff0000',
  FACEBOOK: '#1877f2',
  INSTAGRAM:
    'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
  CLUB_HOUSE: '#f2f0e4',
}

export const Gradation = {
  BK_15: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)',
  BK_25: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
  BK_50: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
  BK_75: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%)',
  PT_30: 'repeating-conic-gradient(#ddd 0% 25%, #eee 0% 50%) 50%/30px 30px',
  PT_LIGHT_30: 'repeating-conic-gradient(#eee 0% 25%, #f3f3f3 0% 50%) 50%/30px 30px',
  LOGO: 'linear-gradient(56.58deg, #FF7800 25.2%, #FFE600 84.49%);',
}
