import React, { AnchorHTMLAttributes, HTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/fanmeUi/constants/style'

type FmeTextProps = {
  size?: number
  href?: string
  textColor?: string
} & WithChildren &
  HTMLAttributes<HTMLSpanElement>

const LinkText = styled.a<FmeTextProps>`
  font-size: ${props => props.size || 15}px;
  color: ${Colors.ORANGE} !important;
  font-weight: 500;
`
const Text = styled.span<FmeTextProps>`
  color: ${props => props.textColor || Colors.BLACK};
`

const sizeLabelToPixel = (str: TextSize): number => {
  switch (str) {
    case 'small':
      return 13
    case 'normal':
      return 15
    case 'large':
      return 18
    case 'massive':
      return 28
  }
}

const FmeLinkText = ({
  children,
  textSize,
  href,
  target,
  onClick,
}: WithChildren & WithTextSize & AnchorHTMLAttributes<any>) => {
  return (
    <LinkText
      onClick={onClick}
      href={href}
      target={target}
      size={sizeLabelToPixel(textSize || 'normal')}
    >
      {children}
    </LinkText>
  )
}

const FmeText = ({ textColor, children }: FmeTextProps & WithChildren) => {
  return <Text textColor={textColor}>{children}</Text>
}

export { FmeLinkText, FmeText }
