import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useCurrentUser } from '@/contexts/AuthContext'
import { useTermsAgreementPopup } from '@/contexts/TermsAgreementPopupContext'
import useCurrentCreatorState from '@/libs/useCurrentCreatorState'
import { get } from '@/pages/api/base'
import { FmeOverlayLayer } from '@/fanmeUi/elements/overlay'
import { FmeBottomPopup } from '@/fanmeUi/components/FmePopup'
import { FmePanelInner, FmeSpace } from '@/fanmeUi/shared/base'
import { FmeLayoutContainer, FmeLayoutTextCentered } from '@/fanmeUi/layouts/center'
import { FmeLinkText } from '@/fanmeUi/elements/text'
import FmeCheckbox from '@/fanmeUi/form/FmeCheckbox'
import { FmeButton } from '@/fanmeUi/elements/button'
import { Colors } from '@/constants/styles/color'

const AttentionMark = () => {
  return (
    <div
      style={{
        width: 64,
        height: 64,
        borderRadius: 32,
        overflow: 'hidden',
        backgroundColor: Colors.ULTRA_LIGHT_GRAY,
        textAlign: 'center',
        lineHeight: '96px',
        margin: `0 auto`,
      }}
    >
      <svg
        width="12"
        height="44"
        viewBox="0 0 12 44"
        fill={Colors.BLACK}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.3334 37.9998C11.3334 40.9454 8.9456 43.3332 6.00008 43.3332C3.05456 43.3332 0.666748 40.9454 0.666748 37.9998C0.666748 35.0543 3.05456 32.6665 6.00008 32.6665C8.9456 32.6665 11.3334 35.0543 11.3334 37.9998Z" />
        <path d="M0.666748 5.99984C0.666748 3.05432 3.05456 0.666504 6.00008 0.666504C8.9456 0.666504 11.3334 3.05432 11.3334 5.99984V21.9998C11.3334 24.9454 8.9456 27.3332 6.00008 27.3332C3.05456 27.3332 0.666748 24.9454 0.666748 21.9998V5.99984Z" />
      </svg>
    </div>
  )
}

const TermsAgreementPopup = ({ ignore = false }: { ignore?: boolean }) => {
  const [lastTerms, setLastTerms] = useState<any>()
  const [showTermsAgreement, setShowTermsAgreement] = useState(false)
  const [isNotice, setIsNotice] = useState(false)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [denied, setDenied] = useState(false)
  const { currentUser } = useCurrentUser()

  const buildStateKey = () => {
    return `doc.${lastTerms.slug}:${lastTerms.version}${isNotice ? '.notice' : ''}`
  }
  const termsAgreementState = useCurrentCreatorState(lastTerms ? buildStateKey() : null)
  const { setShowTermsAgreementPopup } = useTermsAgreementPopup()

  useEffect(() => {
    if (currentUser) {
      // ユーザログイン中
      get(`/doc/terms?future=1`).then(doc => {
        if (doc.slug) {
          const now = moment()
          setIsNotice(moment(doc.start_date).isAfter(now) && now.unix() >= doc.remind_before_date)
          setLastTerms(doc)
        }
      })
    }
  }, [currentUser])

  useEffect(() => {
    if (lastTerms && termsAgreementState.fetched && !termsAgreementState.value()) {
      setShowTermsAgreement(true)
      setShowTermsAgreementPopup(true)
    }
  }, [termsAgreementState, lastTerms])

  if (!showTermsAgreement || ignore) {
    return null
  }

  return (
    <>
      <FmeOverlayLayer visible={true} />
      <FmeBottomPopup header={{ title: 'FANME利用規約' }}>
        <FmePanelInner>
          <FmeLayoutContainer>
            {isNotice ? (
              <>
                <FmeLayoutTextCentered>
                  <p>
                    {moment(lastTerms.start_date).format('YYYY年MM月DD日')}より
                    <br />
                    利用規約が変更になります。
                  </p>
                  <FmeSpace size={2} />
                  <FmeLinkText href={`/terms/${lastTerms.version}`} target={`_blank`}>
                    新しい利用規約を確認
                  </FmeLinkText>
                  <FmeSpace size={2} />
                </FmeLayoutTextCentered>
                <FmeSpace />
                <FmeButton
                  buttonStyle={'black'}
                  onClick={async () => {
                    await termsAgreementState.update(moment().format('YYYYMMDD'))
                    setShowTermsAgreement(false)
                  }}
                  fullWidth={true}
                >
                  閉じる
                </FmeButton>
                <FmeSpace />
              </>
            ) : (
              <>
                {denied ? (
                  <>
                    <FmeSpace size={1} />
                    <AttentionMark />
                    <FmeSpace size={2} />
                    <FmeLayoutTextCentered>
                      同意を確認できませんでした。 <br />
                      再度内容を確認する場合はページを
                      <br />
                      再読み込みしてください
                    </FmeLayoutTextCentered>
                    <FmeSpace size={3} />
                    <FmeButton
                      buttonStyle={'black'}
                      onClick={async () => {
                        window.location.reload()
                      }}
                      fullWidth={true}
                    >
                      再読み込み
                    </FmeButton>
                  </>
                ) : (
                  <>
                    <FmeSpace size={1} />
                    <FmeLayoutTextCentered>
                      <FmeLinkText href={`/terms/${lastTerms.version}`} target={`_blank`}>
                        利用規約
                      </FmeLinkText>
                      が変更になりました。
                      <p>新規約をご覧いただき、同意ください。</p>
                      <FmeSpace size={2} />
                      <FmeCheckbox
                        name={'agree'}
                        value={agreeTerms}
                        onChange={c => {
                          setAgreeTerms(c)
                        }}
                      >
                        上記に同意する
                      </FmeCheckbox>
                      <FmeSpace size={2} />
                    </FmeLayoutTextCentered>
                    <FmeSpace />
                    <FmeButton
                      disabled={!agreeTerms}
                      onClick={async () => {
                        if (agreeTerms) {
                          await termsAgreementState.update(moment().format('YYYYMMDD'))
                          setShowTermsAgreement(false)
                        }
                      }}
                      fullWidth={true}
                    >
                      利用規約に同意して利用する
                    </FmeButton>
                    <FmeSpace />
                    <FmeLayoutTextCentered>
                      <FmeLinkText
                        onClick={() => {
                          setDenied(true)
                        }}
                        textSize={'small'}
                      >
                        同意しない
                      </FmeLinkText>
                    </FmeLayoutTextCentered>
                  </>
                )}
                <FmeSpace />
              </>
            )}
          </FmeLayoutContainer>
        </FmePanelInner>
      </FmeBottomPopup>
    </>
  )
}

export default TermsAgreementPopup
