import getConfig from 'next/config'
import axios, { AxiosInstance } from 'axios'
import { parseCookies } from 'nookies'

type RequestOptions = {
  auth?: boolean
}

const { publicRuntimeConfig } = getConfig()

const axiosInstances: { [key: string]: AxiosInstance } = {}

const customAxios = (options: RequestOptions = { auth: true }): AxiosInstance => {
  const key = `${options.auth ? 'guarded' : 'public'}`
  if (axiosInstances[key]) {
    return axiosInstances[key]
  }

  axiosInstances[key] ||= axios.create({
    baseURL: publicRuntimeConfig.API_BASE_URL,
    timeout: 10000,
    headers: {},
  })

  axiosInstances[key].interceptors.request.use(
    req => {
      const cookies = parseCookies()
      const token = cookies[<string>process.env.NEXT_PUBLIC_TOKEN_KEY]

      if (options.auth && !token) {
        // tokenがないとリダイレクトされる
        const url = new URL(publicRuntimeConfig.AUTH_URL)
        const currentUrl = new URL(location.href)
        currentUrl.searchParams.delete('authorize')
        url.searchParams.set('return_url', currentUrl.toString())
        location.href = url.toString()
        return req
      }

      if (token && req.headers) {
        req.headers.Authorization = `Bearer ${token}`
      }

      return req
    },
    error => {
      return Promise.reject(error)
    },
  )

  return axiosInstances[key]
}

export const post = async (
  url: string,
  params: any,
  config: any = {},
  options: RequestOptions = { auth: true },
) => {
  return await customAxios(options).post(url, params, config)
}

export const put = async (url: string, params: any, options: RequestOptions = { auth: true }) => {
  return await customAxios(options).put(url, params)
}

export const patch = async (url: string, params: any, options: RequestOptions = { auth: true }) => {
  return await customAxios(options).patch(url, params)
}

export const get = async (
  url: string,
  config: any = {},
  options: RequestOptions = { auth: true },
) => {
  return (await customAxios(options).get(url, config))?.data || {}
}

export const postDelete = async (
  url: string,
  params: any,
  options: RequestOptions = { auth: true },
) => {
  return await customAxios(options).delete(url, {
    data: params,
  })
}

export const putForm = async (
  url: string,
  params: any,
  options: RequestOptions = { auth: true },
) => {
  return await customAxios(options).put(url, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
