import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react'
import Router from 'next/router'
import getConfig from 'next/config'
import { useAuth } from '@/contexts/AuthContext'
import { CreatorData } from '@/types/Creator.type'

const { publicRuntimeConfig } = getConfig()

type LPNavigationContextType = {
  toCreatorPage: () => void
  toInfoPage: () => void
  loginData: CreatorData | null
  isSignIn: boolean
  setIsSignIn: (visible: boolean) => void
  getLoginUserData: () => Promise<void>
}

const LPNavigationContext = createContext<LPNavigationContextType | undefined>(undefined)

export const LPNavigationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useAuth()
  const [loginData, setLoginData] = useState<CreatorData | null>(null)
  const [isSignIn, setIsSignIn] = useState(false)

  const toCreatorPage = () => {
    if (!loginData) return
    Router.push(`/@${loginData.account_identity}`)
  }

  const getLoginUserData = async () => {
    try {
      const res = await fetch(`${publicRuntimeConfig.API_BASE_URL}/creators/self`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(res => res.json())
      setLoginData(res)
    } catch (e) {
      console.error(e)
      setLoginData(null)
    }
  }

  const toInfoPage = () => {
    Router.push('https://fanme-support.zendesk.com/hc/ja/requests/new')
  }

  useEffect(() => {
    if (token) {
      setIsSignIn(true)
      getLoginUserData()
    }
  }, [token])

  const value = {
    toCreatorPage,
    toInfoPage,
    loginData,
    isSignIn,
    setIsSignIn,
    getLoginUserData,
  }

  return <LPNavigationContext.Provider value={value}>{children}</LPNavigationContext.Provider>
}

export const useLPNavigation = () => {
  const context = useContext(LPNavigationContext)
  if (context === undefined) {
    throw new Error('useLPNavigation must be used within a LPNavigationProvider')
  }
  return context
}
