import React, { createContext, useContext, useState } from 'react'

type TermsAgreementPopupContext = {
  showTermsAgreementPopup: boolean
  setShowTermsAgreementPopup: (showTermsAgreementPopup: boolean) => void
}

const TermsAgreementPopupContext = createContext<TermsAgreementPopupContext>({
  showTermsAgreementPopup: false,
  setShowTermsAgreementPopup: () => {},
})

type Props = {
  children: React.ReactNode
}

const TermsAgreementPopupProvider = ({ children }: Props) => {
  const [showTermsAgreementPopup, setShowTermsAgreementPopup] = useState(false)

  return (
    <TermsAgreementPopupContext.Provider
      value={{ showTermsAgreementPopup, setShowTermsAgreementPopup }}
    >
      {children}
    </TermsAgreementPopupContext.Provider>
  )
}

export default TermsAgreementPopupProvider
export const useTermsAgreementPopup = () => useContext(TermsAgreementPopupContext)
