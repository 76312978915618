import React from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/fanmeUi/constants/style'

type CheckboxProps = {
  onChange?: (checked: boolean) => void
} & InputProps &
  WithChildren

const CheckIcon = styled.span<{ checked: boolean; pos: 'left' | 'right' }>`
  display: inline-block;
  position: relative;
  line-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin: ${props => (props.pos == 'right' ? '0 0 0 8px' : '0 8px 0 0')};
  background-color: ${props => (props.checked ? Colors.FANME_YELLOW : Colors.BK_GRAY)};
`

const FmeCheckbox = ({ name, children, value, onChange }: CheckboxProps) => {
  const renderIcon = (pos: 'left' | 'right') => (
    <CheckIcon pos={pos} checked={Boolean(value)}>
      <svg
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill={Colors.WHITE}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.1608 12.8L0 7.708L2.136 5.6008L5.1608 8.5856L13.864 0L16 2.1072L5.1608 12.8Z"
          fill="white"
        />
      </svg>
    </CheckIcon>
  )
  return (
    <>
      <label htmlFor={`input-${name}`} style={{ display: 'inline-flex', cursor: 'pointer' }}>
        {renderIcon('left')}
        {typeof children === 'string' ? (
          <span style={{ fontSize: 13, lineHeight: '24px' }}>{children}</span>
        ) : (
          children
        )}
        <input
          id={`input-${name}`}
          type={`checkbox`}
          checked={Boolean(value)}
          name={name}
          style={{ display: 'none' }}
          onChange={
            onChange
              ? e => {
                  onChange(!value)
                }
              : undefined
          }
        />
      </label>
    </>
  )
}

export default FmeCheckbox
