import React, { FC, ReactNode, useEffect } from 'react'
import { initializeFirebaseMessaging, listenOnMessage } from './messaging'

type MessagingProviderProps = {
  children: ReactNode
}

export const MessagingProvider: FC<MessagingProviderProps> = ({ children }) => {
  useEffect(() => {
    initializeFirebaseMessaging().then(ok => {
      if (!ok) {
        return
      }
      listenOnMessage(payload => {
        // handle message
        console.log({ payload })
      })
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', e => {
          console.log({ e })
        })
      }
    })
  }, [])
  return <>{children}</>
}
