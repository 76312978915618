import { THEMES } from '../../types/Creator.type'

export type ColorItem = { colorValue: string; colorName: THEMES }

export const Colors = {
  WHITE: '#fff',
  ULTRA_LIGHT_GRAY: '#f8f8f8',
  PRIMARY_GRAY: '#222222',
  BK_GRAY: '#EFF5FF',
  BK_GRAY_1: '#F5F5F5',
  NOTAVAILABLE: '#CCCCCC',
  VERY_LIGHT_GRAY: '#eee',
  LOGO_GRAY: '#D6E4F0',
  LIGHT_GRAY: '#ccc',
  GRAY: '#999',
  LESS_DARK_GRAY: '#666',
  DARK_GRAY: '#444',
  DARKER_GRAY: '#2E2E2E',
  LIGHT_BLUE: '#20D5EC',
  BLACK: '#333',
  ULTRA_BLACK: '#010101',
  OFFICIAL: '#20d5ec',
  ORANGE: '#FFA800',
  RED_ORANGE: '#FF6D43',
  FANME_YELLOW: '#ffe600',
  ERROR: '#ff5050',
  SUCCESS: '#02bd9e',
  RED: '#FF5050',
  TRANSPARENT_GRAY: 'rgba(0, 0, 0, 0.5)',
  TRANSPARENT_LIGHT_GRAY: 'rgba(0, 0, 0, 0.3)',
  TRANSPARENT_LIGHT_YELLOW: 'rgba(255, 230, 0, 0.1)',
  TRANSPARENT: '#00000000',
  LIGHT_GRAY_BLUE: '#E4EBF7',
  GRAY_BLUE: '#C3CEDF',
  PURE_BLACK: '#000000',
  SILVER_GRAY: '#999999',
  PALE_GRAY: '#EEEEEE',
  LIGHT_NAVY: '#c3cedf',
  GREEN: '#90CC55',
  DARK_RED: '#E64B37',
  SHADOW_GRAY: 'rgba(62, 62, 62, 0.25)',
  RASPBERRY: '#83266e',
  CYBER_YELLOW: '#ffd600',
  FANDANGO: '#b53599',
  PALE_YELLOW: '#FFF3D7',
  DARK_SLATE_GRAY: '#40383e',
}

export const Styles = {
  radius: 16,
}

export const SnsColors = {
  TIKTOK: '#010101',
  TWITTER: '#1da1f2',
  LINE: '#06c755',
  YOUTUBE: '#ff0000',
  FACEBOOK: '#1877f2',
  INSTAGRAM:
    'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
  CLUB_HOUSE: '#f2f0e4',
}

export const Gradation = {
  BK_15: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)',
  BK_25: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
  BK_50: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
  BK_75: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%)',
  PT_30: 'repeating-conic-gradient(#ddd 0% 25%, #eee 0% 50%) 50%/30px 30px',
  PT_LIGHT_30: 'repeating-conic-gradient(#eee 0% 25%, #f3f3f3 0% 50%) 50%/30px 30px',
  LOGO: 'linear-gradient(56.58deg, #FF7800 25.2%, #FFE600 84.49%);',
  LOGO_Clr2_45:
    'linear-gradient(45deg, rgba(140, 30, 230, 1),rgba(255, 85, 175, 1),rgba(255, 200, 20, 1));',
  GRADATION_BORDER: 'linear-gradient(200deg, #0078E7 0%, #D94DFF 100%)',
}

// テーマカラー専用
export const BACKGROUND_COLOR_LIGHT: ColorItem[] = [
  {
    colorName: THEMES.light1,
    colorValue: '#FFEAD6',
  },
  {
    colorName: THEMES.light2,
    colorValue: '#FFFFD6',
  },
  {
    colorName: THEMES.light3,
    colorValue: '#EAFFD6',
  },
  {
    colorName: THEMES.light4,
    colorValue: '#D6FFD6',
  },
  {
    colorName: THEMES.light5,
    colorValue: '#FFD6D6',
  },
  {
    colorName: THEMES.light6,
    colorValue: '#D6FFEA',
  },
  {
    colorName: THEMES.light7,
    colorValue: '#D6FFFF',
  },
  {
    colorName: THEMES.light8,
    colorValue: '#D6EAFF',
  },
  {
    colorName: THEMES.light9,
    colorValue: '#BED8FF',
  },
  {
    colorName: THEMES.light10,
    colorValue: '#EAD6FF',
  },
  {
    colorName: THEMES.light11,
    colorValue: '#FFD6FF',
  },
  {
    colorName: THEMES.light12,
    colorValue: '#FFD6EA',
  },
  {
    colorName: THEMES.light13,
    colorValue: '#CFCFCF',
  },
  {
    colorName: THEMES.light14,
    colorValue: '#EAEAEA',
  },
  {
    colorName: THEMES.light15,
    colorValue: '#FFFFFF',
  },
]

export const BACKGROUND_COLOR_DARK: ColorItem[] = [
  {
    colorName: THEMES.dark1,
    colorValue: '#BC5C04',
  },
  {
    colorName: THEMES.dark2,
    colorValue: '#7F7400',
  },
  {
    colorName: THEMES.dark3,
    colorValue: '#578000',
  },
  {
    colorName: THEMES.dark4,
    colorValue: '#2A8003',
  },
  {
    colorName: THEMES.dark5,
    colorValue: '#037C50',
  },
  {
    colorName: THEMES.dark6,
    colorValue: '#058080',
  },
  {
    colorName: THEMES.dark7,
    colorValue: '#0457DC',
  },
  {
    colorName: THEMES.dark8,
    colorValue: '#1532CD',
  },
  {
    colorName: THEMES.dark9,
    colorValue: '#8125DD',
  },
  {
    colorName: THEMES.dark10,
    colorValue: '#BE24BE',
  },
  {
    colorName: THEMES.dark11,
    colorValue: '#DA1E7C',
  },
  {
    colorName: THEMES.dark12,
    colorValue: '#E62828',
  },
  {
    colorName: THEMES.dark13,
    colorValue: '#454545',
  },
  {
    colorName: THEMES.dark14,
    colorValue: '#757575',
  },
  {
    colorName: THEMES.dark15,
    colorValue: '#222222',
  },
]

export const themeColors: { [key: string]: any } = {
  light: {
    text: Colors.BLACK,
    lighter: {
      bg: Colors.WHITE,
      font: Colors.LIGHT_GRAY,
      s_bg: Colors.BLACK,
      s_font: Colors.WHITE,
    },
    normal: {
      bg: Colors.WHITE,
      font: Colors.LIGHT_GRAY,
      s_bg: Colors.BLACK,
      s_font: Colors.WHITE,
    },
  },
  dark: {
    text: Colors.WHITE,
    lighter: {
      bg: Colors.DARK_GRAY,
      font: Colors.LIGHT_GRAY,
      s_bg: Colors.WHITE,
      s_font: Colors.BLACK,
    },
    normal: {
      bg: Colors.DARK_GRAY,
      font: Colors.GRAY,
      s_bg: Colors.WHITE,
      s_font: Colors.BLACK,
    },
  },
}

export const contentShadow = {
  dark: '0px 0px 5px 0px rgba(255, 255, 255, 0.5)',
  light: '1px 1px 5px 0px rgba(0, 0, 0, 0.5)',
}
