import React from 'react'
import Router from 'next/router'
import getConfig from 'next/config'
import Image from 'next/image'
import Link from 'next/link'
import { event as gevent } from '@/pages/api/gtag'
import { useLPNavigation } from '@/contexts/LPNavigationContext'
import CloseMenuIcon from '@/assets/img/lp/CloseMenuIcon.webp'
import GradientButton from '@/components/lp/GradientButton'

const { publicRuntimeConfig } = getConfig()

interface Props {
  visible: boolean
  setVisible: (val: boolean) => void
}

const Menu = ({ visible, setVisible }: Props) => {
  const { toCreatorPage, isSignIn } = useLPNavigation()

  const toSignIn = () => {
    gevent({
      action: 'lp_menu_login',
      category: 'login',
      transport: 'beacon',
      cb: () => {
        Router.push(`${publicRuntimeConfig.AUTH_URL}`)
      },
    })
  }

  const toSignUp = () => {
    gevent({
      action: 'lp_menu_newcreate',
      category: 'register',
      transport: 'beacon',
      cb: () => {
        Router.push(`${publicRuntimeConfig.AUTH_URL}?proc=signup`)
      },
    })
  }

  return (
    <>
      <div
        className={visible ? 'side-menu-bg visible' : 'side-menu-bg'}
        onClick={() => setVisible(false)}
      />
      <nav
        id="sideMenu"
        className={`${
          visible ? 'side-menu visible' : 'side-menu'
        } bg-white rounded-tl-3xl rounded-bl-3xl`}
      >
        <div className="flex flex-col h-full py-4 px-6">
          <div className="flex justify-end" onClick={() => setVisible(false)}>
            <Image src={CloseMenuIcon} alt="Close" width={42} />
          </div>
          <div className="flex-grow mt-8">
            <div className="mb-8">
              <Link href="/about" passHref>
                <div className="cursor-pointer" onClick={() => setVisible(false)}>
                  <div className="font-roboto font-[700] text-[36px] leading-[42px] tracking-wide text-right text-BLACK mb-0">
                    ABOUT
                  </div>
                  <div className="font-['Noto_Sans_JP'] font-normal text-[13px] leading-[19px] text-edge-cap tracking-[0.03em] text-right text-BLACK">
                    FANMEとは
                  </div>
                </div>
              </Link>
            </div>
            <div className="mb-8">
              <Link href="/contact" passHref>
                <div className="cursor-pointer" onClick={() => setVisible(false)}>
                  <div className="font-roboto font-[700] text-[36px] leading-[42px] tracking-wide text-right text-BLACK mb-0">
                    CONTACT
                  </div>
                  <div className="font-['Noto_Sans_JP'] font-normal text-[13px] leading-[19px] text-edge-cap tracking-[0.03em] text-right text-BLACK">
                    お問い合わせ
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div id="sideMenuBottom" className="w-full mt-auto flex flex-col items-center">
            {isSignIn ? (
              <GradientButton
                title="マイページへ"
                width={190}
                height={60}
                variant="small"
                onClick={toCreatorPage}
              />
            ) : (
              <>
                <GradientButton
                  title="ログイン"
                  width={190}
                  height={60}
                  variant="small"
                  onClick={toSignIn}
                />
                <div className="mt-4">
                  <GradientButton
                    title="新規登録"
                    width={190}
                    height={60}
                    variant="small"
                    backgroundColor="#000"
                    textColor="#fff"
                    onClick={toSignUp}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  )
}

export default Menu
