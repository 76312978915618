import { useEffect, useRef, useState } from 'react'
import { AxiosResponse } from 'axios'
import { get, post } from '@/pages/api/base'

type CreatorState = {
  key: string | null
  fetched: boolean
  none: () => boolean
  value: () => string | null
  update: (value: any) => Promise<string>
}

const useCurrentCreatorState = (key: string | null, local = false): CreatorState => {
  const valueRef = useRef<string | null>(null)
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    if (key) {
      if (local) {
        if (sessionStorage) {
          valueRef.current = sessionStorage.getItem(`fmcs:${key}`) || ''
        } else {
          valueRef.current = ''
        }
        setFetched(true)
      } else {
        get(`/creators/self/creator_state?key=${key}`).then((v: any) => {
          valueRef.current = v.value || ''
          setFetched(true)
        })
      }
    }
  }, [key])

  return {
    key: key,
    fetched: fetched,
    none: () => valueRef.current == null,
    value: () => valueRef.current,
    update: async (value: any) => {
      const v = `${value}`
      if (v !== valueRef.current) {
        if (local) {
          if (sessionStorage) {
            sessionStorage.setItem(`fmcs:${key}`, value)
            valueRef.current = sessionStorage.getItem(`fmcs:${key}`) || ''
          }
        } else {
          valueRef.current =
            (
              (await post(`/creators/self/creator_state`, {
                key: key,
                value: v,
              })) as AxiosResponse
            )?.data?.value || ''
        }
      }

      return valueRef.current || ''
    },
  }
}

export default useCurrentCreatorState
