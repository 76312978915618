import React from 'react'

type GradientButtonProps = {
  title: string
  onClick?: () => void
  width?: number
  backgroundColor?: string
  textColor?: string
  variant?: 'default' | 'large' | 'small'
  height?: number
}

const GradientButton: React.FC<GradientButtonProps> = ({
  title,
  onClick,
  width = 532,
  backgroundColor = '#FFFFFF',
  textColor = '#222222',
  variant = 'default',
  height = 74,
}) => {
  const getButtonClass = () => {
    switch (variant) {
      case 'large':
        return 'text-[28px] sm:text-[32px]'
      case 'small':
        return 'text-[15px] sm:text-[15px]'
      default:
        return 'text-[18px] sm:text-[24px]'
    }
  }

  return (
    <div
      onClick={onClick}
      className="relative w-full"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        filter: 'drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.1))',
        cursor: 'pointer',
      }}
    >
      {/* Rectangle 14 */}
      <div
        style={{
          position: 'absolute',
          width: `calc(100% - 6px)`,
          height: `calc(100% - 4px)`,
          left: `calc(50% - (100% - 6px)/2 - 3px)`,
          top: '0',
          background: 'linear-gradient(38.38deg, #0050D2 16.75%, #00B9E6 50%, #00E69B 85.42%)',
          borderRadius: '35px',
        }}
      />
      {/* Rectangle 13 */}
      <div
        style={{
          position: 'absolute',
          width: `calc(100% - 6px)`,
          height: `calc(100% - 4px)`,
          left: `calc(50% - (100% - 6px)/2 + 3px)`,
          top: '4px',
          background: 'linear-gradient(38.38deg, #8C1EE6 14.58%, #FF55AF 50%, #FFC814 85.42%)',
          borderRadius: '36px',
        }}
      />
      {/* Rectangle 15 */}
      <div
        style={{
          boxSizing: 'border-box',
          position: 'absolute',
          width: `calc(100% - 6px)`,
          height: `calc(100% - 6px)`,
          left: `calc(50% - (100% - 6px)/2)`,
          top: '3px',
          background: backgroundColor,
          border: `2px solid ${backgroundColor}`,
          borderRadius: '35px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span
          className={`${getButtonClass()} font-sansJP font-black flex items-center text-center tracking-[0.03em]`}
          style={{
            color: textColor,
          }}
        >
          {title}
        </span>
      </div>
    </div>
  )
}

export default GradientButton
