/*
 * Googleのリファレンス(下記URL)に記載されているイベントの型を定義
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=ja&client_type=gtag
 *
 * 購買に関係しない未使用のイベントは、将来の利用が想定できるものだけ定義している。
 */

export enum TransportType {
  Beacon = 'beacon',
}

export enum Currency {
  JPY = 'JPY',
}

export enum PaymentType {
  CreditCard = 'CreditCard',
  ConvenienceStore = 'ConvenienceStore',
}

export enum LoginMethod {
  Google = 'Google',
  Line = 'Line',
  Email = 'Email',
}

export enum ShareMethod {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Line = 'Line',
  Email = 'Email',
  SMS = 'SMS',
  CopyLink = 'CopyLink',
  Other = 'Other',
}

export type Item = {
  item_id: string
  item_name: string
  affiliation?: string | undefined
  coupon?: string | undefined
  string?: string | undefined
  creative_name?: string | undefined
  creative_slot?: string | undefined
  discount?: number | undefined
  index?: number | undefined
  item_brand?: string | undefined
  item_category?: string | undefined
  item_category2?: string | undefined
  item_category3?: string | undefined
  item_category4?: string | undefined
  item_category5?: string | undefined
  item_list_id?: string | undefined
  item_list_name?: string | undefined
  item_variant?: string | undefined
  location_id?: string | undefined
  price?: number | undefined
  promotion_id?: string | undefined
  promotion_name?: string | undefined
  quantity?: number | undefined
}

export type AddPaymentInfo = {
  currency: string
  value: number
  coupon?: string | undefined
  payment_type?: string | undefined
  items: Array<Item>
}

export type AddShippingInfo = {
  currency: string
  value: number
  coupon?: string | undefined
  shipping_tier?: string | undefined
  items: Array<Item>
}

export type AddToCart = {
  currency: string
  value: number
  items: Array<Item>
}

export type RemoveFromCart = {
  currency: string
  value: number
  items: Array<Item>
}

export type AddToWishlist = {
  currency: string
  value: number
  items: Array<Item>
}

export type BeginCheckout = {
  currency: string
  value: number
  coupon?: string | undefined
  items: Array<Item>
}

export type GenerateLead = {
  currency: string
  value: number
}

export type Purchase = {
  currency: string
  transaction_id: string
  value: number
  coupon?: string | undefined
  shipping?: number | undefined
  tax?: number | undefined
  items: Array<Item>
}

export type Refund = {
  currency: string
  transaction_id: string
  value: number
  coupon?: string | undefined
  shipping?: number | undefined
  tax?: number | undefined
  items: Array<Item>
}

export type SelectItem = {
  item_list_id?: string | undefined
  item_list_name?: string | undefined
  items: Array<Item>
}

export type SelectPromotion = {
  creative_name?: string | undefined
  creative_slot?: string | undefined
  promotion_id?: string | undefined
  promotion_name?: string | undefined
  items?: Array<Item> | undefined
}

export type Share = {
  method?: ShareMethod | undefined
  content_type?: string | undefined
  item_id?: string | undefined
}

export type ViewCart = {
  currency: string
  value: number
  items: Array<Item>
}

export type ViewItem = {
  currency: string
  value: number
  items: Array<Item>
}

export type ViewItemList = {
  item_list_id?: string | undefined
  item_list_name?: string | undefined
  items: Array<Item>
}

export type ViewPromotion = {
  creative_name?: string | undefined
  creative_slot?: string | undefined
  promotion_id?: string | undefined
  promotion_name?: string | undefined
  items: Array<Item>
}
