import React, { useState } from 'react'
import Image from 'next/image'
import Router from 'next/router'
import Link from 'next/link'
import getConfig from 'next/config'
import { event as gevent } from '@/pages/api/gtag'
import OpenMenuIcon from '@/assets/img/lp/OpenMenuIcon.webp'
import HomeMenu from '@/components/molecules/Home/Menu'
import { useLPNavigation } from '@/contexts/LPNavigationContext'
import Typography from '@/components/atoms/Typography'
import GradientButton from '@/components/lp/GradientButton'

const { publicRuntimeConfig } = getConfig()

type HeaderMenuProps = Record<string, never>

const HeaderMenu: React.FC<HeaderMenuProps> = () => {
  const { toCreatorPage, isSignIn } = useLPNavigation()
  const [sideMenuVisible, setSideMenuVisible] = useState(false)

  const toSignIn = () => {
    Router.push(`${publicRuntimeConfig.AUTH_URL}`)
  }

  const toSignUp = () => {
    Router.push(`${publicRuntimeConfig.AUTH_URL}?proc=signup`)
  }

  const openMenu = () => {
    setSideMenuVisible(true)
    gevent({
      action: 'lp_menu',
      category: 'other',
    })
  }

  return (
    <header className="fixed top-2 left-0 right-0 z-50 flex justify-center w-full">
      <div className="w-full max-w-lp-hero-section shadow-[0px_0px_16px_rgba(0,_0,_0,_0.08)] rounded-[8px] bg-WHITE box-border flex flex-row items-center justify-between pt-[10px] pb-[14px] pl-[23px] pr-[15px] my-2 sm:my-4 mx-2 md:mx-4 lg:mx-0">
        <HomeMenu visible={sideMenuVisible} setVisible={setSideMenuVisible} />

        <div className="w-[178px]">
          <Link href="/">
            <Image
              src="/Logo.svg"
              alt="FANME Logo"
              width={158}
              height={80}
              objectFit="contain"
              objectPosition="left"
            />
          </Link>
        </div>

        <div className="hidden md:flex flex-row justify-between gap-10">
          <div className="flex flex-col items-start justify-start gap-[8px]">
            <Link href="/about" className="flex flex-col items-start justify-start  no-underline">
              <Typography variant="header-title" className="tracking-wider cursor-pointer">
                ABOUT
              </Typography>
              <Typography variant="header-item" className="tracking-wider whitespace-nowrap">
                FANMEとは
              </Typography>
            </Link>
          </div>
          <div className="w-[104px] flex flex-col items-start justify-start gap-[8px] text-left">
            <Link
              href="/contact"
              className="w-[104px] flex flex-col items-start justify-start text-left no-underline "
            >
              <Typography variant="header-title" className="tracking-wider cursor-pointer">
                CONTACT
              </Typography>
              <Typography variant="header-item" className="tracking-wider text-center w-full">
                お問い合わせ
              </Typography>
            </Link>
          </div>
        </div>

        <div className="hidden md:flex flex-row items-center justify-end gap-[24px]">
          {isSignIn ? (
            <div className="w-[130px]">
              <GradientButton
                title="マイページへ"
                width={130}
                height={60}
                variant="small"
                onClick={toCreatorPage}
              />
            </div>
          ) : (
            <>
              <div className="w-[130px]">
                <GradientButton
                  title="ログイン"
                  width={130}
                  height={60}
                  variant="small"
                  onClick={toSignIn}
                />
              </div>
              <div className="w-[130px]">
                <GradientButton
                  title="新規登録"
                  width={130}
                  height={60}
                  variant="small"
                  backgroundColor="#000"
                  textColor="#fff"
                  onClick={toSignUp}
                />
              </div>
            </>
          )}
        </div>

        {/* SP用のハンバーガーメニュー */}
        <button className="md:hidden" onClick={openMenu}>
          <Image src={OpenMenuIcon} alt="Menu" width={42} />
        </button>
      </div>
    </header>
  )
}

export default HeaderMenu
