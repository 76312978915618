import React from 'react'
import type { NextPage } from 'next'

type props = {
  className?: string
}

const Footer: NextPage<props> = ({ className = '' }) => {
  return (
    <footer
      className={`self-stretch bg-[#222] flex justify-center py-8 sm:py-[48px] px-2 box-border max-w-full gap-6 z-[5] text-center sm:text-left text-[14px] sm:text-[16px] text-WHITE font-sansJP ${className}`}
    >
      <div className="max-w-lp-section w-full flex flex-col sm:flex-row items-center justify-between">
        <div className="w-full sm:w-[464px] flex flex-row items-center sm:items-center justify-center gap-[20px] sm:gap-[80px] max-w-full">
          <div className="flex flex-col items-center sm:items-start justify-start">
            <a
              href="https://www.torihada.co.jp/"
              target="_blank"
              rel="noopener noreferrer"
              className="relative leading-[16px] z-[1] cursor-pointer hover:underline text-WHITE no-underline"
            >
              運営会社
            </a>
          </div>
          <a
            href="/terms"
            target="_blank"
            rel="noopener noreferrer"
            className="relative leading-[16px] z-[1] cursor-pointer hover:underline text-WHITE no-underline"
          >
            利用規約
          </a>
          <a
            href="https://www.torihada.co.jp/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            className="relative leading-[16px] z-[1] cursor-pointer hover:underline text-WHITE no-underline"
          >
            プライバシーポリシー
          </a>
        </div>
        <div className="w-full sm:w-[456px] flex flex-col items-center sm:items-start justify-start pt-4 sm:pt-[4px] text-center sm:text-right text-[12px] sm:text-[16px] text-GRAY font-[Roboto]">
          <div className="relative tracking-[0.02em] font-medium z-[1]">
            COPYRIGHT 2020 TORIHADA INC. ALL RIGHT RESERVED.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
