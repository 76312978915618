import React from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/fanmeUi/constant'

const FmeLayoutContainer = styled.div<{ color?: string; bgColor?: string }>`
  background-color: ${props => props.bgColor || 'transparent'};
  color: ${props => props.color || Colors.BLACK};
  * {
    font-size: 14px;
    font-weight: 500;
  }
  p {
    margin: 0;
    & + p {
      margin-top: 1em;
    }
  }
`

const FmeLayoutTextCentered = ({ children }: WithChildren) => {
  return <div style={{ textAlign: 'center' }}>{children}</div>
}

const FmeFlexCenter = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin: 0 auto;
  }
`

export { FmeLayoutTextCentered, FmeLayoutContainer, FmeFlexCenter }
