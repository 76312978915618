import React, { createContext, FC, useContext, useReducer } from 'react'

//今後おすすめのクリエーターさんのリストもcontextに入れる

interface IFollowerContext {
  isFollowing: boolean
  isFollowOn: boolean
  setFollow: (status: boolean) => void
}
const INITIAL_STATE = {
  isFollowing: false,
  isFollowOn: false,
}
const INITIALCONTEXT = {
  isFollowing: false,
  isFollowOn: false,
  setFollow: () => {},
}
interface IAction {
  type: string
  payload: { isFollowing: boolean }
}

const FollowerContext = createContext<IFollowerContext>(INITIALCONTEXT)

const Follow_ACTION_TYPES = {
  SET_FOLLOW: 'SET_FOLLOW',
}

const followerReducer = (state: { isFollowing: boolean; isFollowOn: boolean }, action: IAction) => {
  const { type, payload } = action
  switch (type) {
    case Follow_ACTION_TYPES.SET_FOLLOW:
      return {
        ...state,
        isFollowing: payload.isFollowing,
      }
    default:
      return state
  }
}

const FollowerProvider: FC<{ children: React.ReactElement }> = ({ children }) => {
  const [{ isFollowing, isFollowOn }, dispatch] = useReducer(followerReducer, INITIAL_STATE)

  const setFollow = (status: boolean) => {
    dispatch({
      type: Follow_ACTION_TYPES.SET_FOLLOW,
      payload: { isFollowing: status },
    })
  }
  const value = {
    isFollowing,
    setFollow,
    isFollowOn,
  }

  return <FollowerContext.Provider value={value}>{children}</FollowerContext.Provider>
}

export default FollowerProvider

export const useFollowerContext = () => useContext(FollowerContext)
