import React, { createContext, FC, ReactChild, useContext, useState } from 'react'

type ToastState = ReturnType<typeof useToastState>

type ToastContextProps = {
  toast: ToastState
  headerToast: ToastState
  coverImageToast: ToastState
  coverVideoToast: ToastState
}

type ToastProviderProps = {
  children: ReactChild
}

const defaultToastState: ToastState = { visible: false, setVisible: () => {} }

const useToastState = () => {
  const [visible, setVisible] = useState<boolean>(false)
  return { visible, setVisible }
}

const ToastContext = createContext<ToastContextProps>({
  toast: defaultToastState,
  headerToast: defaultToastState,
  coverImageToast: defaultToastState,
  coverVideoToast: defaultToastState,
})

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const toast = useToastState()
  const headerToast = useToastState()
  const coverImageToast = useToastState()
  const coverVideoToast = useToastState()
  const value = {
    toast,
    headerToast,
    coverImageToast,
    coverVideoToast,
  }
  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

export const useToast = () => useContext(ToastContext)
